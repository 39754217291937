<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        small
        class="mr-2"
        v-bind="attrs"
        v-on="on"
      >
        mdi-pencil
      </v-icon>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">
          Set new layer name and Udm
        </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            ref="appUserForm"
            v-model="valid"
            lazy-validation
          >
            <v-row>
              <v-text-field
                v-model="newName"
                label="Insert layer name"
              />
            </v-row>
            <v-row>
              <v-text-field
                v-model="newUdm"
                label="Insert layer udm"
              />
            </v-row>
            <v-row>
              <v-text-field
                v-model="newColor"
                :rules="code_rules"
                label="Insert layer color"
              />
              <div
                id="layerColor"
                class="ml-3 my-auto"
                :style="`background-color: ${newColor}`"
              />
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="close()"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :disabled="!valid"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { getRegExp } from '@/formRules.js';

export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      valid: true,
      newColor: this.item.layer_color,
      newName: this.item.layer_label,
      newUdm: this.item.udm,
      code_rules: getRegExp('hex_code')
    };
  },
  computed: {
    ...mapState([])
  },
  methods: {
    save() {
      if (this.newColor.length > 0) {
        this.item.layer_color = this.newColor;
      } else {
        this.newColor = this.item.layer_color;
      }

      this.item.layer_label = this.newName;
      this.item.udm = this.newUdm;
      this.dialog = false;
    },
    close() {
      this.newColor = this.item.layer_color;
      this.newName = this.item.layer_label;
      this.newUdm = this.item.udm;
      this.dialog = false;
    }
  }
};
</script>
